/* CSS override for "main"-container holding all pages */
.Content {
  align-items: flex-start;
  padding: 5rem 2rem 5rem 2rem;
  letter-spacing: .01em;
  text-rendering: optimizelegibility !important;
  max-width: 900px;
}

.video-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin: 3rem 3rem 3rem 3rem;
  max-width: 900px;
  color: whitesmoke;
}

.video-page .section-title {
  font-size: 32px;
  color: white;
  margin-top: 1rem;
}
