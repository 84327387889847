.Sign-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border-radius: 2px;
    /*background: white;*/
    max-height: 100%;
    /*height: 550px;*/
}

.Sign-in-title {
    font-weight: bold;
    font-size: calc(16px + 0.5vmin);
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.Sign-in-text {
    margin: 0.5rem 1rem 1rem;
    font-size: calc(10px + 0.2vmin);
    text-align: center;
    max-width: 75%;
}

.Nav-profile-button {
    font-size: 16px;
}

.Nav-profile-button:hover {
    transform: scale(1.2);
}


