.App {
    max-height: 100%;
    min-height: 100vh;
    font-family: Titillium-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}


.App-header {
    background-color: rgba(40, 44, 52, 0.05);
    min-height: 50px;
    max-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: calc(10px + 1vmin);
    color: white;
    padding: 1rem;
}

.Header-logo-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Header-navigation-container {
    display: flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    align-items: center;
    width: 100%;
    font-size: calc(10px + 0.5vmin);
}

.Navigation-item {
    margin-left: 15px;
}

.Content {
    display: flex;
    min-height: 80vh;
    /*background: #61dafb;*/
    min-width: 100%;
    margin: auto;
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.Card {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 15px;
    border-radius: 2px;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
}

.App-link {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 15px;
    font-weight: normal;
}

.App-link:hover {
    color: rgba(255, 255, 255, 0.8);
}

.App-link-external {
    text-decoration: none;
    color: var(--light80);
    font-weight: 500;
}

.App-link-external:hover {
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
}

.nav-active {
    text-decoration: none;
    font-weight: bold;
    color: white;
}

.content {
    position: absolute;
    left: 15px;
    right: 15px;
}

.content-enter {
    opacity: 0;
    transform: scale(0.5);
}

.content-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.content-exit {
    opacity: 1;
    transform: scale(1);
}

.content-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}