.Idea-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 75%;
    max-width: 900px;
    margin-top: 5%;
    margin-bottom: 10%;
    align-content: center;
    justify-content: center;
    justify-items: center;
}
.Idea-list-container .title{
    background-color: rgba(255, 255, 255, .3);
    border-radius: .3rem;
    padding: 0.5rem;
    color: black;
    margin-bottom:2%
    
}
.Idea-list-container .button {
    border-radius: .3rem;
    color: black;

}

.Idea-top-container {
    display: flex;
    flex-direction: row-reverse;
    min-width: 100%;
    width: 100%;
    margin-bottom: 1%;
}

.Idea-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    max-width: 900px;
    gap: 1rem;
    font-size: calc(10px + 0.2vmin);
    margin-left: 15px;
    margin-right: 15px;
    align-items: center;
}

.Cards {
    display: flex;
    position: relative;
    flex-direction: column;
    background: white;
    padding: 15px;
    border-radius: 2px;
    height: 100%;
    width: 100%;
    /*min-height: 300px;*/
    /*max-height: 300px;*/
}

.Cards .Card-top-row {
    margin: 5px 5px 5px 5px;
    /*background: #DD0890;*/
    padding-right: 2px;
    align-content: center;
}

.Cards .Card-title {
    display: flex;
    font-size: calc(10px + 0.5vmin);
    min-height: 50px;
}

.Cards .Card-text {
    font-size: calc(10px + 0.2vmin);
    margin-bottom: 20px;
    max-width: 100%;
    word-wrap: anywhere;
    -ms-word-wrap: anywhere;
}

.Cards .Card-number {
    font-size: calc(10px + 0.1vmin);
    margin-top: 10px;
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.Heart-filter-button {
    outline: none;
}

.Icon-heart {
    font-size: 20px;
    margin-left: 20px;
    padding-right: 5px;
}

.Icon-heart:hover {
    transform: scale(1.2);
}

.Icon-comment {
    font-size: 18px;
}

.Icon-comment:hover {
    transform: scale(1.2);
}

.Icon-edit {
    font-size: 20px;
    margin-left: 20px;
}

.Icon-edit:hover {
    transform: scale(1.2);
}

.Modal-form-idea-edit-container {
    max-height: 50vh;
}

.Input-text-area-idea-edit {
    font-size: calc(10px + 1vmin);
    min-height: 30vh;
    max-height: 100%;
}

@media (max-width: 768px) {
    .Idea-grid-container {
        display: flex;
        flex-direction: column;
        width: 95%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .Idea-grid-container {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .Idea-grid-container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    }
}

@media (min-width: 1201px) {
    .Idea-grid-container {
        max-width: 1200px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

