/* CSS override for "main"-container holding all pages */
.Content {
  align-items: flex-start;
  padding: 5rem 2rem 5rem 2rem;
  letter-spacing: .01em;
  text-rendering: optimizelegibility !important;
  max-width: 900px;
}

/* CSS override for main Card-style */
.Card {
  width: 100%;
  height: 100%;
  padding: 3rem 2rem 3rem 2rem;
}

.Card:hover {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: scale(0.98);
}

.Card:hover h2,
.Card:hover h3 {
  color: var(--highlight);
}

/* Generic */
.background-white-with-opacity {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 4px;
}

.background-white-with-corners {
  background-color: rgba(255, 255, 255, 1);
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 4px;
}

/* MethodsPage */
.methods-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin: 3rem 3rem 3rem 3rem;
  max-width: 900px;
}

/* Page-specific */

.section-cards {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  gap: 10px;
  /* max-height: 600px; */
}

.section-title {
  font-size: 32px;
  color: white;
  margin-top: 1rem;
}

.section-description {
  font-size: 22px;
  color: whitesmoke;
  padding-right: 4rem;
}

/* MethodLayout - Wrapper for selected-page */
.method-layout {
  display: flex;
  flex-direction: column;
  color: whitesmoke;
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 16px;
  margin-top: 50px;
  width: 100%;
  max-width: 800px;
}

.methods-layout-title {
  font-size: 32px;
  color: whitesmoke;
}

.methods-layout-content {
  display: flex;
  flex-direction: column;
}

.methods-layout-content>* {
  margin-bottom: 2rem;
}

.methods-layout-content-description {
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
}

.methods-layout-content a {
  font-size: 18px;
  font-weight: 500;
  color: whitesmoke;
  margin-top: 2rem;
}

.methods-layout-content a::before {
  font-size: 25px;
  content: '☛';
  padding: 5px;
  align-items: center;
}

.methods-layout-content a:hover {
  color: var(--light)
}

.methods-layout-content li {
  font-size: 16px;
  margin-top: 0.8rem;
}

.methods-layout-content ul {
  margin: 0;
  padding: 0;
  padding-left: 1.50rem;
}

.methods-layout-content ol {
  margin: 0;
  padding: 0;
  font-size: 14px;
  padding-left: 1.50rem;
}

/* MethodInformation */

.method-information-container {
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  text-align: left;
  gap: 2rem;
  width: '100%'
}

.method-information-container section {
  width: 150px;
}

.method-information-container b {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.method-information-container p {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.text-decoration-none {
  list-style-type: none;
}

.li-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.li-row b {
  width: 75px;
  text-align: left;
  margin-right: 1rem;
}

.li-row p {
  padding: 0;
  margin: 0;
  text-align: left;
}

.hover-able {
  cursor: pointer;
}

.query-flex-column {
  flex-direction: column;
}

.query-flex-row {
  flex-direction: row;
}

.download-link {
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  color: black;
  padding-inline: 2rem;
}

.download-link:hover {
  color: var(--highlight)
}

.download-link::before {
  content: "↓";
  padding-right: 0.5rem;
}


/* MediaQueries */

@media (max-width: 768px) {

  /* CSS override for main Card-style */
  .Card {
    width: 90%;
    height: 400px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {

  /* CSS override for main Card-style */
  .Card {
    width: 90%;
  }
}

@media (max-width: 1024px) {

  /* CSS override for main Card-style */
  .Card {
    width: 90%;
  }

  .section-cards {
    flex-direction: column;
    max-height: fit-content;
  }

  .query-flex-column {
    flex-direction: row;
  }

  .query-flex-row {
    flex-direction: column;
  }
}