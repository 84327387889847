.Main-Container-Timeline {
    display: flex;
    flex-direction: column;
    margin: 1px;
    padding: 4rem;
    max-width: 1200px;
}

.Container-Timeline {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    color: white;
    min-width: 300px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.Container-Timeline b {
    font-weight: 400;
    font-size: calc(12px + 0.5vmin);
}

.Container-Timeline i {
    font-size: calc(12px + 0.2vmin);
}

.Container-Timeline p {
    font-weight: 400;
    font-size: calc(10px + 0.5vmin);
}

.Time-line-icon {
    font-size: 32px;
    background-color: white;
    border-radius: 50%;
    padding: 2px;
}

.Time-line-icon-child {
    font-size: 22px;
    background-color: white;
    border-radius: 50%;
    padding: 2px;
}

.Time-line-icon.planned {
    color: var(--light);
}

.Time-line-icon-child.planned {
    color: var(--light);
}

.Time-line-icon.progress {
    color: var(--light80);
}

.Time-line-icon-child.progress {
    color: var(--light80);
}

.Time-line-icon.done {
    color: var(--dark);
}

.Time-line-icon-child.done {
    color: var(--dark);
}

.ant-timeline-item-head {
    background-color: transparent;
    border-radius: 50%;
    padding: 0.2rem;
}

.About-project-container {
    display: flex;
    flex-direction: column;
    color: white;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}

.About-project-container h1 {
    color: white;
    font-size: calc(20px + 0.5vmin);
}

.About-project-container header {
    color: white;
    margin-bottom: 15px;
    font-size: calc(12px + 0.5vmin);
}

.About-project-container span {
    color: white;
    font-weight: 400;
    margin-bottom: 15px;
    font-size: calc(12px + 0.5vmin);
}

.About-project-container ul {
    padding: 0;
    margin: 0;
    font-size: 18px;
    list-style: none;
    margin-left: 1rem;
}

.About-project-container ul li {
    margin-bottom: 1rem;
}


.About-project-container i {
    font-size: calc(12px + 0.2vmin);
}

@media (max-width: 768px) {
    .Main-Container-Timeline {
        display: flex;
        flex-direction: column;
    }

    .About-project-container {
        margin-top: 5vh;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .Main-Container-Timeline {
        display: flex;
        flex-direction: row;
    }

    .About-project-container {
        padding-left: 3rem;
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .Main-Container-Timeline {
        display: flex;
        flex-direction: row;
    }

    .About-project-container {
        padding-left: 3rem;
    }
}

@media (min-width: 1201px) {
    .Main-Container-Timeline {
        display: flex;
        flex-direction: row;
    }

    .About-project-container {
        padding-left: 3rem;
    }
}

.pulse-child {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes pulse-child {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.25, 1.25, 1.25);
        transform: scale3d(1.25, 1.25, 1.25);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse-child {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.25, 1.25, 1.25);
        transform: scale3d(1.25, 1.25, 1.25);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.pulse-parent {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes pulse-parent {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(0.75, 0.75, 0.75);
        transform: scale3d(0.75, 0.75, 0.75);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse-parent {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(0.75, 0.75, 0.75);
        transform: scale3d(0.75, 0.75, 0.75);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.Time-line-icon {
    color: var(--highlight)
}

.Time-line-icon.parent-now {
    color: var(--light80);
    animation-name: pulse-parent;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.Time-line-icon-child.now {
    color: var(--dark);
    animation-name: pulse-child;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}