:root {
    --auth-sign-in-link-color: rgb(203, 154, 185);
    --purple-badge: rgb(209, 7, 143);
    --purple-light: rgb(197, 6, 141);
    --purple-lighter: rgb(220, 67, 171);
    --purple-dark-medium: rgb(117, 41, 170);
    --purple-dark: rgb(75, 0, 130);
    --purple-blue: rgb(0, 139, 230);
    --light: rgb(8, 221, 157);
    --light80: rgb(10, 145, 104);
    --light-opacity-70: rgba(8, 221, 157, 0.7);
    --badge: rgb(0, 133, 78);
    --dark: rgb(0, 92, 42);
    --dark-gray: rgb(45, 45, 45);
    --highlight: #4180c9;
    --main-font-family: Titillium-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}

body {
    /* background-image: linear-gradient(128deg, #DD0890 0%, #4B0082 71.93%); */
    background-image: linear-gradient(128deg, #08DD9D 0%, #003C82 71.93%);
    background-position: center;
    background-attachment: fixed;
    font-family: var(--main-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'Titillium-Regular';
    src: local('Titillium-Regular'), url(assets/fonts/Titillium/TitilliumWeb-Regular.ttf) format('opentype');
}