.Form-container {
    width: 500px;
    background: white;
    border-radius: 2px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 25px 15px 15px;
}

.Input-title-idea {
    font-size: calc(10px + 1vmin);
}


.Input-text-area-idea {
    font-size: calc(10px + 1vmin);
    min-height: 200px;
    max-height: 250px;
}
