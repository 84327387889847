.Text-field-char-counter-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    font-size: calc(10px + 0.5vmin);
}

.Input-email {
    font-size: calc(10px + 1vmin);
}


.Input-text-area-idea-edit {
    font-size: calc(10px + 1vmin);
    min-height: 200px;
    max-height: 250px;
}
