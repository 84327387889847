/* CSS override for "main"-container holding all pages */
.Content {
  align-items: flex-start;
  padding: 5rem 2rem 5rem 2rem;
  letter-spacing: .01em;
  text-rendering: optimizelegibility !important;
  max-width: 900px;
}

.green-tech-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin: 3rem 3rem 3rem 3rem;
  max-width: 900px;
  color: whitesmoke;
}

.green-tech-page .section-title {
  font-size: 32px;
  color: white;
  margin-top: 1rem;
}

.green-tech-page .section-description {
  font-size: 22px;
  color: whitesmoke;
  padding-right: 4rem;
}

.green-tech-page .challenge {
  background-color: rgba(255,255,255,.1);
  border-radius: .5rem;
  padding: 2rem;
}

.green-tech-page .challenge a {
  color: #222;
  text-decoration: underline;
}

.green-tech-page .challenge h3 {
  font-size: 24px;
  color: whitesmoke;
}

.green-tech-page h3 {
  margin: 1rem 0 0 0;
  font-size: 24px;
  color: whitesmoke;
}

.green-tech-page ul {
  list-style: square;
}

.green-tech-page ul li {
  margin-bottom: .5rem;
}
.green-tech-page .link{
  background-color: rgba(255, 255, 255, .1);
    border-radius: .3rem;
    padding: 1rem;
    color:black;
    text-decoration: underline;
}